<template>
  <div class="fixed-bottom notifications">
    <div :class="'alert alert-' + notification.type" role="alert" v-for="notification in notifications">
      <button @click="removeNotification(notification)" type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
      {{notification.message}}
    </div>
  </div>
</template>

<script lang="ts">
  import { Component, Vue } from "vue-property-decorator"
  import {namespace} from "vuex-class"
  import {NotificationType, Notification} from "../notify"
  const notifications = namespace('notifications')
  @Component
  export default class Notifications extends Vue{
    @notifications.Getter notifications
    @notifications.Mutation removeNotification
  }
</script>

<style scoped lang="sass">
  .notifications
    max-height: 30%
    opacity: .9
    z-index: 10000
    margin-bottom: 10px
    position: fixed
    right: 10px
    left: 10px
    @media (max-width: 768px)
      width: 95%
    @media (min-width: 768px)
      min-width: 400px
      max-width: 100%
    .alert
      max-height: 100%
</style>
